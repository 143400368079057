const pl = {
    sidebar: {
        applicants: 'Aplikanci',
        mentors: 'Mentorzy',
        tutors: 'Korepetytorzy',
        sales: 'Sprzedaż',
        pricing: 'Cennik',
        inquiries: 'Ubezpieczenia',
    },
    applicants: {
        applicantView: {
            title: 'Aplikant {{name}}',
            generalInformation: {
                title: 'Informacje ogólne',
                firstName: 'Imię',
                lastName: 'Nazwisko',
                countries: 'Gdzie chcesz studiować',
                displayableName: 'Nazwa wyświetlana dla innych',
                email: 'Email',
                phone: 'Telefon',
                studyStartYear: 'Rok rozpoczęcia studiów',
                notSelected: 'Brak danych',
                university: 'Uczelnia #{{index}}',
                fieldOfStudy: {
                    label: 'Kierunek #{{index}}',
                    durationMonth: ' - {{duration}}-miesięczne',
                    durationYear: ' - Roczne',
                    durationYears: ' - {{duration}}-letnie',
                },
                baccalaureateType: {
                    title: 'Rodzaj matury',
                    polish_baccalaureate: 'Polska matura',
                    international_baccalaureate: 'Matura międzynarodowa',
                    custom: 'Inny rodzaj matury',
                },
                studyLevel: {
                    title: 'Poziom studiów',
                    bachelor: "Licencjackie",
                    master: "Magisterskie",
                },
                applicationOrigin: {
                    title: 'Jak trafiłeś na EduCat?',
                    facebook: 'Facebook',
                    instagram: 'Instagram',
                    friend: 'Od znajomego',
                    school_presentation: 'Prezentacja w szkole',
                    teacher: 'Od nauczyciela',
                    webinar: 'Zobaczyłam/em webinar',
                    parents: 'Od rodziców',
                    other: 'Inne',
                },
                baccalaureateSubjects: {
                    title: 'Przedmioty zdawane na maturze',
                    primary: 'Podstawowy',
                    extended: 'Rozszerzony',
                    noData: 'Brak danych'
                }
            },
            alert: {
                downloadAccepted: 'Plik pobrano.',
                downloadError : 'Coś poszło nie tak.',
            },
            imageCard: {
                title: 'Zdjęcie Aplikanta'
            },
        },
        applicantList: {
            title: 'Lista aplikantów:',
            buttons: {
                exportList: 'Eksportuj do .xlsx',
                search: 'Szukaj Aplikantów'
            },
            table: {
                applicantName: 'Imię i nazwisko',
                applicantId: 'ID aplikanta',
                email: 'Email',
                phone: 'Telefon',
                actionButtons: {
                    title: 'Akcje',
                    acceptRegistration: 'Zatwierdź rejestrację',
                    acceptOnboarding: 'Zatwierdź onboarding',
                    details: 'Szczegóły'
                },
                noData: 'Nie znaleziono Aplikantów',
            },
            search: 'Wyszukiwanie Aplikanta'
        },
    },
    mentors: {
        status: {
            noAccountRegistrationConfirmation: '[1/6] Nieaktywny',
            noRegistrationSurvey: '[2/6] Brak ankiety Rejestracyjnej',
            registrationSurveyApproval: '[3/6] Ankieta Rejestracyjna niezaakceptowana',
            noOnboardingSurvey: '[4/6] Brak Ankiety Onboardingowej',
            onboardingSurveyApproval: '[5/6] Ankieta Onboardingowa niezaakceptowana',
            mentorActive: '[6/6] Aktywny',
            mentorDeactivated: 'Nieaktywny'
        },
        mentorList: {
            title: 'Lista mentorów',
            buttons: {
                activeList: 'Aktywni',
                inactiveList: 'Nieaktywni',
                exportPersonalDataList: 'Raport Osobowy',
                exportRatesList: 'Raport Stawek',
                search: 'Szukaj Mentorów'
            },
            table: {
                mentorName: 'Imię i nazwisko',
                contact: 'Kontakt',
                status: 'Status',
                actionButtons: {
                    title: 'Akcje',
                    acceptRegistration: 'Zatwierdź rejestrację',
                    acceptOnboarding: 'Zatwierdź onboarding',
                    details: 'Szczegóły'
                },
                noData: 'Nie znaleziono Mentorów',
            },
            alert: {
                registrationAccepted: 'Rejestracja mentora została zatwierdzona.',
                onboardingAccepted: 'Onboarding mentora został zatwierdzony.',
            },
            search: 'Wyszukiwanie Mentora'
        },
        mentorView: {
            title: 'Mentor {{name}}',
            accountSettings: {
                title: 'Ustawienia konta',
                status: {
                    label: 'Status konta',
                    active: 'Aktywne',
                    inactive: 'Nieaktywne'
                }
            },
            generalInformation: {
                title: 'Informacje ogólne',
                firstName: 'Imię',
                lastName: 'Nazwisko',
                displayableName: 'Nazwa wyświetlana dla innych',
                email: 'Email',
                phone: 'Telefon',
                studyLevel: 'Stopień studiów',
                countries: 'Kraj studiów',
                university: 'Uczelnia #{{index}}',
                fieldOfStudy: {
                    label: 'Kierunek #{{index}}',
                    durationMonth: ' - {{duration}}-miesięczne',
                    durationYear: ' - Roczne',
                    durationYears: ' - {{duration}}-letnie',
                },
                status: {
                    label: 'Status #{{index}}',
                    student: 'Student',
                    graduate: 'Absolwent'
                },
                helpRange: {
                    label: 'Zakres pomocy',
                    noData: 'Brak danych'
                },
                realms: {
                    label: 'Dziedziny',
                    noData: 'Brak wybranych dziedzin'
                },
                birthdate: 'Data urodzenia',
                address: 'Adres',
                languages: 'Język prowadzenia zajęć',
                shortDescription: 'O mnie',
                programme: 'Mój styl pracy',
                classes: 'Korepetycje',
                notSelected: 'N/A'
            },
            platformPrices: {
                title: 'Ceny na platformie',
                services: {
                    label: 'Usługi Mentora',
                    noData: 'Brak usług'
                },
                hourPackages: {
                    label: "Pakiety godzin",
                    noData: 'Brak Pakietów'
                },
                servicePackages: {
                    label: "Pakiety usług",
                    noData: 'Brak Pakietów'
                },
                freeHelpPackages: {
                    label: "Pakiety pomocy bezpłatnej",
                    true: 'TAK',
                    false: 'NIE',
                },
                applicantNumber: "Liczba aplikantów",
            },
            onboardingInformation: {
                title: 'Dane z ankiety onboardingowej',
                nationality: {
                    label: "Jakiej jesteś narodowości?",
                    polish: "Polskiej",
                    other: "Innej"
                },
                businessOwner: {
                    label: "Czy masz działalność gospodarczą?",
                    true: "Mam działalność",
                    false: "Nie mam działalności"
                },
                over26: {
                    label: "Czy masz 26 lat lub wyżej?",
                    true: "26 lat lub więcej",
                    false: "poniżej 26 lat"
                },
                  student: {
                    label: "Czy jesteś studentem?",
                    true: "Jestem studentem",
                    false: "Nie jestem studentem"
                },
                bankAccountNumber: "Numer konta bankowego",
                bankData: "Dane banku",
                residency: {
                    label: "Czy jesteś rezydentem podatkowym innego kraju niż Polska?",
                    true: "Tak",
                    false: "Nie",
                    notSure: "Nie wiem"
                },
            },
            imageCard: {
                title: 'Zdjęcie Mentora'
            },
            studyLevel: {
				bachelor: "Licencjackie",
				master: "Magisterskie",
			},
        }
    },
    pricing: {
        title: 'Cennik',
        buttons: {
            save: 'Zapisz cennik',
            edit: 'Edytuj cennik'
        }
    },
    sales: {
        title: 'Sprzedaż',
        buttons: {
            exportList: 'Eksportuj do .xlsx',
        },
        table: {
            title: 'Lista sprzedaży',
            noData: 'Nie ma danych sprzedażowych',
            tableItemNumber: 'Numer pozycji',
            applicantName: 'Imię i nazwisko Aplikanta',
            mentorName: 'Imię i nazwisko Mentora',
            package: 'Pakiet',
            service: 'Usługa',
            status: {
                label: 'Status',
                completed: 'Zakończona',
                inProgress: 'W trakcie',
            },
            purchaseDate: 'Data zakupu',
            grossPrice: 'Cena brutto',
            expand: 'rozwiń',
            collapse: 'zwiń',
        }
    },
    inquiries: {
        inquiryList: {
            title: 'Lista polis',
            totals: {
                title: 'Raport zbiorczy wybranego zakres',
                insuranceNo: 'Liczba opłaconych ubezpieczeń',
                premiumSum: 'Suma opłaconych składek',
                axaFeeSum: 'Kwota prowizji AXA',
                educatFeeSum: 'Kwota prowizji EduCat',
            },
            actions: {
                btnFilters: {
                    show: 'Pokaż filtry',
                    hide: 'Ukryj filtry',
                },
                btnNewInquiry: 'Stwórz nową polisę',
                btnInquirySettings: 'Ustawienia',
                btnSubmit: 'Filtruj',
                btnMassSettle: 'Rozlicz zaznaczone',
                confirmAction: 'Potwierdź akcję:'
            },
            filters: {
                purchaseDate: 'Zakres dat stworzenia formularza polisy',
                inquiryId: 'Numer polisy',
                status: 'Status',
                btnSubmit: 'Filtruj',
                btnInquiryReportCSV: 'Pobierz listing do CSV',
                btnAxaReportCSV: 'Raport AXA',
                statuses: {
                    status_paid: 'Zapłacone',
                    status_expired: 'Zakończone',
                    status_modified: 'Zmienione',
                    status_draft: 'Draft',
                    status_active: 'Aktywne',
                    status_expired_draft: 'Draft Zakończony',
                }
            },
            table: {
                inquiryData: 'Dane dotyczące polisy',
                subjectData: 'Dane klienta',
                financialData: 'Dane finansowe polisy',
                noData: 'Nie znaleziono Polis',
                createDate: 'Data stworzenia formularza',
                purchaseDate: 'Data sprzedaży',
                inquiryNo: 'Numer polisy',
                inquiryDateRange: 'Okres ubezpieczenia',
                status: 'Status',
                name: 'Imię i nazwisko',
                phone: 'Numer kontaktowy',
                email: 'Email',
                school: 'Nazwa uczelni',
                inquiryPremium: 'Wartości składki ubezpieczenia',
                axaFee: 'Kwota prowizji AXA',
                educatFee: 'Kwota prowizji Educat',
                isSettled: 'Czy rozliczone z Educat Services',
                options: 'Opcje',
                statusBadge: {
                    status_paid: 'Zapłacone',
                    status_active: 'Aktywne',
                    status_modified: 'Zmienione',
                    status_draft: 'Draft',
                    status_expired_draft: 'Draft Zakończony',
                    status_expired: 'Zakończone',
                },
                actions: {
                    details: 'Szczegóły',
                    edit: 'Edytuj',
                    settle: 'Oznacz jako rozliczone'
                },
                singleInquirySettled: 'Polisa została rozliczona z Educat Services',
                multipleInquiresSettled: 'Wybrane polisy zostały rozliczone z Educat Services',
            },
            createModal: {
                title: 'Nowa polisa'
            },
            viewModal: {
                title: 'Szczegóły polisy',
                btnEdit: 'Edytuj',
                downloadInsurance: 'Pobierz polisę',
                sendInquiryEmail: 'Wyślij polisę ponownie',
                inquiryEmailSent: 'Email ze szczegółami polisy został wysłany',
                orderDetails: {
                    title: 'Szczegóły Zamówienia',
                    createdAt: 'Data stworzenia formularz',
                    status: 'Status polisy',
                    purchaseAt: 'Data sprzedaży',
                    inquiryPremium: 'Wartości składki ubezpieczenia',
                    axaFee: 'Kwota prowizji AXA',
                    educatFee: 'Kwota prowizji Educat',
                    insuranceDateRange: 'Okres ubezpieczenia',
                    insuranceNo: 'Numer polisy'
                },
                inquirySubjectDetails: {
                    title: 'Dane ubezpieczanego:',
                    firstName: 'Imię',
                    lastName: 'Nazwisko',
                    birthDate: 'Data urodzenia',
                    phone: 'Numer telefonu',
                    email: 'Adres email',
                },
                inquirySubjectAddress: {
                    title: 'Adres zamieszkania:',
                    country: 'Kraj',
                    city: 'Miasto',
                    postcode: 'Kod pocztowy',
                    street: 'Adres',
                },
                inquiryDataDetails: {
                    title: 'Informacje dotyczące polisy:',
                    insuranceDuration: 'Okres trwania polisy (miesiące)',
                    insuranceStart: 'Data startu okresu ubezpieczeniowego',
                    destination: 'Kraj zagraniczny wskazany w polisie',
                    tripReason: 'Typ wyjazdu edukacyjnego',
                    tripReasons: {
                        bachelor_degree_studies: 'Licencjackie',
                        engineering_studies: 'Inżynierskie',
                        master_degree_studies: 'Magisterskie',
                        doctoral_studies: 'Doktoranckie',
                        mba_studies: 'MBA',
                        high_school_abroad: 'Liceum za granicą',
                        erasmus_or_exchange: 'Erasmus lub wymiana',
                        other: 'Inne',
                    },
                    school: 'Nazwa uczelni',
                    variant: 'Wybrany wariant ubezpieczenia',
                    clauses: {
                        label: 'Wybrane rozszerzenia ochrony',
                        alcoholClause: 'Klauzula alkoholowa',
                        workClause: 'Praca fizyczna',
                    },
                },
            },
            editModal: {
                title: 'Edycja polisy',
                btnView: 'Szczegóły',
                insuranceUpdated: 'Szczegóły ubezpieczenia zostały zmienione.',
                formControls: {
                    labels: {
                        firstName: 'Imię',
                        lastName: 'Nazwisko',
                        email: 'Email',
                        birthDate: 'Data urodzenia',
                        status: 'Typ studiów',
                        school: 'Uczelnia',
                    },
                    placeholders: {
                        firstName: 'Wpisz tutaj',
                        lastName: 'Wpisz tutaj',
                        birthDate: 'Wybierz datę',
                        email: 'Wpisz tutaj',
                        school: 'Wpisz nazwę',
                    },
                    statuses: {
                        bachelor_degree_studies: 'Licencjackie',
                        engineering_studies: 'Inżynierskie',
                        master_degree_studies: 'Magisterskie',
                        doctoral_studies: 'Doktoranckie',
                        mba_studies: 'MBA',
                        high_school_abroad: 'Liceum za granicą',
                        erasmus_or_exchange: 'Erasmus lub wymiana',
                        other: 'Inne',
                    },
                    buttons: {
                        cancel: 'Anuluj',
                        save: 'Zapisz',
                    },
                },
                editEmail: {
                    title: 'Email podany przy tworzeniu polisy',
                    email: 'Email',
                    selectEmail: 'Wpisz email lub wybierz z listy',
                    changeEmail: 'Aktualizuj email',
                }
            },
            inquirySettingsModal: {
                title: 'Ustawienia',
                variants: {
                    title: 'Ustawienia wariantów',
                    form: {
                        essential: {
                            europe: 'Essential Europa',
                            world: 'Essential World',
                        },
                        premium: {
                            europe: 'Premium Europa',
                            world: 'Premium World',
                        },
                        allInclusive: {
                            europe: 'All Inclusive Europa',
                            world: 'All Inclusive World',
                        }
                    }
                },
                variantsUpdated: 'Ustawienia wariantów zostały zmienione',
                files: {
                    title: 'Pliki dołączane do maili',
                    addDocument: 'Dodaj dokument',
                    updateDocuments: 'Aktualizuj dokumenty',
                    documentsUpdated: 'Lista plików została zaktualizowana',
                    noFilesAdded: 'Brak załączników',
                },
            },
        },
    },
    auth: {
        authFooter: {
            srTitle: 'Stopka strony zawierająca dane kontaktowe, nawigację po stronie i zapisy do newslettera',
            addressColumn: {
                title: 'Educat Sp. z o.o.',
                address1: 'ul. Zygmunta Krasińskiego 2/55',
                address2: '20-709 Lublin, Polska',
                nip: 'NIP: 9662136157',
                regon: 'REGON: 385301298'
            },
            contactColumn: {
                title: 'Kontakt',
                correspondenceAddressTitle: 'Adres korespondencyjny:',
                address1: 'Al. KEN 93 (iDID)',
                address2: '02-777 Warszawa'
            },
            linksColumn: {
                title: 'Educat',
                aboutUs: 'O nas',
                career: 'Kariera',
                cooperation: 'Współpraca',
                contact: 'Kontakt',
                policies: 'Regulamin i polityka prywatności',
                safePayments: 'Bezpieczne płatności',
            },
            newsletterColumn: {
                title: 'Bądź na bieżąco',
                subscribeInfo: 'Subskrybuj nasz newsletter',
                newsletterLabelSR: 'Wpisz swój adres email aby otrzymac newsletter',
                newsletterSubmitButtonSR: 'Przycisk zatwierdzania formularza subskrypcji Newslettera'
            },
            copyright: 'Educat {{ year }} © Wszystkie prawa zastrzeżone.',
            socialIcons: {
                facebook: 'Link prowadzący do profilu na Facebooku',
                instagram: 'Link prowadzący do profilu na Instagramie',
                linkedin: 'Link prowadzący do profilu na Linkedin',
                youtube: 'Link prowadzący do kanalu Youtube',
            }
        },
        formControls: {
            placeholder: 'Wpisz tutaj',
            email: 'Email',
            password: 'Hasło',
            newPassword: 'Nowe hasło',
            repeatPassword: 'Powtórz nowe hasło',
            registrationTermsAgreement: ``,
            phone: 'Telefon',
            firstName: 'Imię',
            lastName: 'Nazwisko',
            registrationTerms: `Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempr.`,
        },
        register: {
            title: 'Zarejestruj się',
            subtitle: 'Platforma EduCat to  miejsce ułatwiające proces przygotowania do studiów. Wypełnij poniższy formularz, aby założyć swój profil.',
            haveAccount: 'Masz już konto w naszym serwisie? ',
            logIn: 'Zaloguj się.',
            registerWith: {
                title: 'lub zarejestruj się bezpośrednio za pomocą swojego konta na:',
                facebook: 'Zarejestruj się przez Facebook',
                google: 'Zarejestruj się przez Google'
            }
        },
        confirmRegistration: {
            title: 'Potwierdź rejestrację',
            subtitle: 'Aby zakończyć rejestrację nacisnij przycisk poniżej.'
        },
        login: {
            title: 'Zaloguj się',
            subtitle: '',
            haveAccount: 'Nie masz jeszcze konta w naszym serwisie? ',
            register: 'Zarejestruj się.',
            resetPassword: 'Nie pamiętasz hasła?',
            loginWith: {
                title: 'lub zaloguj się bezpośrednio za pomocą swojego konta na:',
                facebook: 'Zaloguj się przez Facebook',
                google: 'Zaloguj się przez Google'
            }
        },
        resetPassword: {
            title: 'Zresetuj hasło',
            subtitle: 'Nie martw się, że hasło Ci umknęło :) Wpisz adres email, który został użyty przy rejestracji. Wyślemy na niego dalsze instrukcje.'
        },
        newPassword: {
            title: 'Zmień hasło',
            subtitle: 'Wpisz nowe hasło i kontynuuj przygodę na Platformie EduCat'
        },
        alert: {
            loginSuccess: 'Logowanie zakończone pomyślnie.',
            loginFailed: 'Niepoprawny login lub hasło. Spróbuj jeszcze raz.',
            logout: 'Zostałeś wylogowany.',
            registrationSuccess: 'Dane rejestracyjne wysłane prawidłowo, potwierdź rejestrację klikając w link podany w emailu.',
            confirmRegistrationSuccess: 'Rejestracja została potwierdzona, zaloguj się za pomocą maila i swojego hasła.',
            sendResetPasswordMailSuccess: 'Na twój adres email wysłaliśmy wiadomość z linkiem resetującym hasło.',
            confirmResetPasswordSuccess: 'Twoje hasło zostało zmienione, możesz zalogować się za jego pomocą.',
            authenticationError: 'Musisz być zalogowany aby mieć dostęp do danych.'
        }
    },
    buttons: {
        more: 'Zobacz więcej',
        login: 'Zaloguj się',
        edit: 'Edytuj',
        save: 'Zapisz',
        activate: 'Aktywuj konto',
        deactivate: 'Dezaktywuj konto',
        update: 'Aktualizuj',
        confirm: 'Potwierdź',
        cancel: 'Anuluj',
    },
    profileImageComponent: {
        buttons: {
            change: 'Zmień',
            add: 'Dodaj',
        },
        srButtons: {
            edit: 'Edytuj zdjęcie',
            add: 'Dodaj zdjęcie',
            saveProfileImage: 'Zatwierdź zmianę zdjęcia profilowego',
            cancelProfileImage: 'Anuluj zmianę  zdjęcia profilowego',
            deleteProfileImage: 'Usuń zdjęcie profilowe',
            imageTooBig: 'Zdjęcie profilowe musi mieć wielkość poniżej 1MB.',
        }
    },
    formValidation: {
        errors: {
            minLength: 'Wartość jest zbyt krótka',
            isEmailValid: 'Adres email jest nieprawidłowy',
            isRequired: 'Pole wymagane',
            isCheckedTrue: 'Musi być zaznaczone',
            isTrue: 'Musi być zaznaczone',
            isPasswordSame: 'Hasła nie mogą być różne',
            isStartDateValid: 'Data musi być w przyszłości',
            isStartDateGreaterOrEvenValid: 'Data musi być w przyszłości lub dziś',
            isEndDateValid: 'Data powrotu musi być po dacie wylotu',
            isNumber: 'Wartość musi być liczbą',
            isIbanValid: 'Niewłaściwy format numeru konta bankowego'
        }
    },
    footer: {
        copyright: 'Educat {{ year }} © Wszelkie prawa zastrzeżone',
    },
    country: {
        AFG: 'Afganistan',
        ALB: 'Albania',
        DZA: 'Algieria',
        AND: 'Andora',
        AGO: 'Angola',
        AIA: 'Anguilla',
        ATA: 'Antarktyka',
        ATG: 'Antigua i Barbuda',
        SAU: 'Arabia Saudyjska',
        ARG: 'Argentyna',
        ARM: 'Armenia',
        ABW: 'Aruba',
        AUS: 'Australia',
        AUT: 'Austria',
        AZE: 'Azerbejdżan',
        BHS: 'Bahamy',
        BHR: 'Bahrajn',
        BGD: 'Bangladesz',
        BRB: 'Barbados',
        BEL: 'Belgia',
        BLZ: 'Belize',
        BEN: 'Benin',
        BMU: 'Bermudy',
        BTN: 'Bhutan',
        BLR: 'Białoruś',
        BOL: 'Boliwia',
        BES: 'Bonaire, Sint Eustatius i Saba',
        BIH: 'Bośnia i Hercegowina',
        BWA: 'Botswana',
        BRA: 'Brazylia',
        BRN: 'Brunei',
        IOT: 'Brytyjskie Terytorium Oceanu Indyjskiego',
        VGB: 'Brytyjskie Wyspy Dziewicze',
        BGR: 'Bułgaria',
        BFA: 'Burkina Faso',
        BDI: 'Burundi',
        CHL: 'Chile',
        CHN: 'Chiny',
        HRV: 'Chorwacja',
        CUW: 'Curaçao',
        CYP: 'Cypr',
        TCD: 'Czad',
        MNE: 'Czarnogóra',
        CZE: 'Czechy',
        UMI: 'Dalekie Wyspy Mniejsze Stanów Zjednoczonych',
        DNK: 'Dania',
        COD: 'Demokratyczna Republika Konga',
        DMA: 'Dominika',
        DOM: 'Dominikana',
        DJI: 'Dżibuti',
        EGY: 'Egipt',
        ECU: 'Ekwador',
        ERI: 'Erytrea',
        EST: 'Estonia',
        ETH: 'Etiopia',
        FLK: 'Falklandy',
        FJI: 'Fidżi',
        PHL: 'Filipiny',
        FIN: 'Finlandia',
        FRA: 'Francja',
        ATF: 'Francuskie Terytoria Południowe i Antarktyczne',
        GAB: 'Gabon',
        GMB: 'Gambia',
        SGS: 'Georgia Południowa i Sandwich Południowy',
        GHA: 'Ghana',
        GIB: 'Gibraltar',
        GRC: 'Grecja',
        GRD: 'Grenada',
        GRL: 'Grenlandia',
        GEO: 'Gruzja',
        GUM: 'Guam',
        GGY: 'Guernsey',
        GUF: 'Gujana Francuska',
        GUY: 'Gujana',
        GLP: 'Gwadelupa',
        GTM: 'Gwatemala',
        GNB: 'Gwinea Bissau',
        GNQ: 'Gwinea Równikowa',
        GIN: 'Gwinea',
        HTI: 'Haiti',
        ESP: 'Hiszpania',
        NLD: 'Holandia',
        HND: 'Honduras',
        HKG: 'Hongkong',
        IND: 'Indie',
        IDN: 'Indonezja',
        IRQ: 'Irak',
        IRN: 'Iran',
        IRL: 'Irlandia',
        ISL: 'Islandia',
        ISR: 'Izrael',
        JAM: 'Jamajka',
        JPN: 'Japonia',
        YEM: 'Jemen',
        JEY: 'Jersey',
        JOR: 'Jordania',
        CYM: 'Kajmany',
        KHM: 'Kambodża',
        CMR: 'Kamerun',
        CAN: 'Kanada',
        QAT: 'Katar',
        KAZ: 'Kazachstan',
        KEN: 'Kenia',
        KGZ: 'Kirgistan',
        KIR: 'Kiribati',
        COL: 'Kolumbia',
        COM: 'Komory',
        COG: 'Kongo',
        KOR: 'Korea Południowa',
        PRK: 'Korea Północna',
        CRI: 'Kostaryka',
        CUB: 'Kuba',
        KWT: 'Kuwejt',
        LAO: 'Laos',
        LSO: 'Lesotho',
        LBN: 'Liban',
        LBR: 'Liberia',
        LBY: 'Libia',
        LIE: 'Liechtenstein',
        LTU: 'Litwa',
        LUX: 'Luksemburg',
        LVA: 'Łotwa',
        MKD: 'Macedonia',
        MDG: 'Madagaskar',
        MYT: 'Majotta',
        MAC: 'Makau',
        MWI: 'Malawi',
        MDV: 'Malediwy',
        MYS: 'Malezja',
        MLI: 'Mali',
        MLT: 'Malta',
        MNP: 'Mariany Północne',
        MAR: 'Maroko',
        MTQ: 'Martynika',
        MRT: 'Mauretania',
        MUS: 'Mauritius',
        MEX: 'Meksyk',
        FSM: 'Mikronezja',
        MMR: 'Mjanma',
        MDA: 'Mołdawia',
        MCO: 'Monako',
        MNG: 'Mongolia',
        MSR: 'Montserrat',
        MOZ: 'Mozambik',
        NAM: 'Namibia',
        NRU: 'Nauru',
        NPL: 'Nepal',
        DEU: 'Niemcy',
        NER: 'Niger',
        NGA: 'Nigeria',
        NIC: 'Nikaragua',
        NIU: 'Niue',
        NFK: 'Norfolk',
        NOR: 'Norwegia',
        NCL: 'Nowa Kaledonia',
        NZL: 'Nowa Zelandia',
        OMN: 'Oman',
        PAK: 'Pakistan',
        PLW: 'Palau',
        PSE: 'Palestyna',
        PAN: 'Panama',
        PNG: 'Papua-Nowa Gwinea',
        PRY: 'Paragwaj',
        PER: 'Peru',
        PCN: 'Pitcairn',
        PYF: 'Polinezja Francuska',
        POL: 'Polska',
        PRI: 'Portoryko',
        PRT: 'Portugalia',
        ZAF: 'Południowa Afryka',
        CAF: 'Republika Środkowoafrykańska',
        CPV: 'Republika Zielonego Przylądka',
        REU: 'Reunion',
        RUS: 'Rosja',
        ROU: 'Rumunia',
        RWA: 'Rwanda',
        ESH: 'Sahara Zachodnia',
        KNA: 'Saint Kitts i Nevis',
        LCA: 'Saint Lucia',
        VCT: 'Saint Vincent i Grenadyny',
        BLM: 'Saint-Barthélemy',
        MAF: 'Saint-Martin',
        SPM: 'Saint-Pierre i Miquelon',
        SLV: 'Salwador',
        ASM: 'Samoa Amerykańskie',
        WSM: 'Samoa',
        SMR: 'San Marino',
        SEN: 'Senegal',
        SRB: 'Serbia',
        SYC: 'Seszele',
        SLE: 'Sierra Leone',
        SGP: 'Singapur',
        SXM: 'Sint Maarten',
        SVK: 'Słowacja',
        SVN: 'Słowenia',
        SOM: 'Somalia',
        LKA: 'Sri Lanka',
        USA: 'Stany Zjednoczone',
        SWZ: 'Eswatini',
        SDN: 'Sudan',
        SSD: 'Sudan Południowy',
        SUR: 'Surinam',
        SJM: 'Svalbard i Jan Mayen',
        SYR: 'Syria',
        CHE: 'Szwajcaria',
        SWE: 'Szwecja',
        TJK: 'Tadżykistan',
        THA: 'Tajlandia',
        TWN: 'Tajwan',
        TZA: 'Tanzania',
        TLS: 'Timor Wschodni',
        TGO: 'Togo',
        TKL: 'Tokelau',
        TON: 'Tonga',
        TTO: 'Trynidad i Tobago',
        TUN: 'Tunezja',
        TUR: 'Turcja',
        TKM: 'Turkmenistan',
        TCA: 'Turks i Caicos',
        TUV: 'Tuvalu',
        UGA: 'Uganda',
        UKR: 'Ukraina',
        URY: 'Urugwaj',
        UZB: 'Uzbekistan',
        VUT: 'Vanuatu',
        WLF: 'Wallis i Futuna',
        VAT: 'Watykan',
        VEN: 'Wenezuela',
        HUN: 'Węgry',
        GBR: 'Wielka Brytania',
        VNM: 'Wietnam',
        ITA: 'Włochy',
        CIV: 'Wybrzeże Kości Słoniowej',
        BVT: 'Wyspa Bouveta',
        CXR: 'Wyspa Bożego Narodzenia',
        IMN: 'Wyspa Man',
        SHN: 'Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha',
        ALA: 'Wyspy Alandzkie',
        COK: 'Wyspy Cooka',
        VIR: 'Wyspy Dziewicze Stanów Zjednoczonych',
        HMD: 'Wyspy Heard i McDonalda',
        CCK: 'Wyspy Kokosowe',
        MHL: 'Wyspy Marshalla',
        FRO: 'Wyspy Owcze',
        SLB: 'Wyspy Salomona',
        STP: 'Wyspy Świętego Tomasza i Książęca',
        ZMB: 'Zambia',
        ZWE: 'Zimbabwe',
        ARE: 'Zjednoczone Emiraty Arabskie',
    },
    mentorOnboarding: {
        registration: {
            study_information: {
                title: "1 krok: Informacje o studiach",
                formControls: {
                    studyFieldNotFound: 'Brakuje mojego kierunku',
                    studyLevel: {
                        label: 'Poziom studiów',
                        bachelor: 'Licencjackie',
                        master: "Magisterskie"
                    },
                    removeStudyInformation: 'Usuń kierunek',
                    studyCountry: {
                        label: 'Kraj studiów',
                    },
                    school: {
                        label: 'Uczelnia',
                    },
                    realm: {
                        label: 'Dziedzina #{{index}}',
                    },
                    fieldOfStudy: {
                        label: 'Kierunek #{{index}}',
                    },
                    status: {
                        label: "Status",
                        student: "Student",
                        graduate: "Absolwent",
                        yearOfStudy: "rok studiów"
                    },
                    placeholder: "Wybierz z listy"
                },
                addButton: "Dodaj kolejny kierunek"
            },
            help_range: {
                title: "2 krok: Zakres pomocy",
                additionalInfo: "Zaznacz, w których kategoriach jesteś w stanie pomóc w aplikacji studia według schematu:",
                formControls: {
                    generalConversation: "Ogólna rozmowa o pomocy mentorskiej ",
                    documentPreparation :"Przygotowanie potrzebnych dokumentów aplikacyjnych" ,
                    applicationDocumentCheck:"Sprawdzenie poprawności dokumentów aplikacyjnych ",
                    materialPreparation:"Przygotowanie zakresu materiałów potrzebnych w procesie aplikacyjnym",
                    timetablePreparation:"Stworzenie harmonogramu aplikacji na wybrany kierunek ",
                    documentVerification:"Sprawdzenie poprawności złożonej aplikacji w systemie aplikacyjnym uczelni / pomoc w dodaniu dokumentów",
                    interviewPreparation:"Przygotowanie do rozmowy kwalifikacyjnej" ,
                    applicationExamPreparation: {
                        title: "Przygotowanie do egzaminu aplikacyjnego: (zaznacz poszczególne)",
                        tsa: "TSA",
                        fineArt: "Fine art practical",
                        bmat: "BMAT",
                        ucat: "UCAT",
                        cat: "CAT",
                        elat: "ELAT",
                        hat: "HAT",
                        mat: "MAT",
                        bocconi: "Test Bocconi",
                        lnat: "LNAT",
                        ompt: "OMPT",
                        nsaa: "NSAA",
                        sat: "SAT",
                        step: "STEP",
                        engaa: "ENGAA",
                        mlat: "MLAT",
                        olat: "OLAT",
                        imat: "IMAT",
                        pat: "PAT",
                        philosophy: "Philosophy Test",
                        musicPerformance: "Music performance test",
                        stGallen: "Test St. Gallen",
                    },
                    afterApplicationHelp: "Pomoc poaplikacyjna",
                    helpRealms: "Dziedziny, w których możesz pomóc",
                    placeholder: "Wybierz z listy"
                }
            },
            basic_information: {
                title: "3 krok: Informacje podstawowe",
                formControls: {
                    displayName: "Nazwa wyświetlana dla innych",
                    birthDate:"Data urodzenia",
                    address: "Adres korespondencyjny",
                    languages: {
                        label: "Język prowadzenia zajęć",
                        placeholder: "Wpisz tutaj, oddzielając przecinkiem"
                    },
                    photo: {
                        label: "Zdjęcie",
                        input: "Dodaj zdjęcie z dysku"
                    },
                    inputPlaceholder: "Wpisz tutaj",
                    datePlaceholder: "DD / MM / RRRR"
                }
            },
            mentor_description: {
                title: "4 krok: Opis mentora",
                formControls: {
                    longDescription: "O mnie",
                    programme: "Mój styl pracy",
                    checkbox: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
                    placeholder: "Wpisz tutaj"
                }
            },
            classes: {
                title: "5 krok: Korepetycje",
                formControls: {
                    yes: "tak",
                    no: "no",
                    subjectClasses: "Czy chcesz prowadzić także korepetycje przedmiotowe?",
                    selectedSubject: "Wybrany przedmiot",
                    subjectClassesPlaceholder: "Wybierz przedmiot z listy",
                    maturityExam: {
                        title: "Matura",
                        basic: "matura podstawowa",
                        extended: "matura rozszerzona",
                        international: "matura międzynarodowa IB",
                        math: {
                            analysisASL: "matematyka Analysis and Approaches SL",
                            applicationsISL: "matematyka Applications and Interpretations SL",
                            analysisAHL: "matematyka Analysis and Approaches HL",
                            applicationsIHL: "matematyka Applications and Interpretations HL"
                        },
                        biology: {
                            biologyHL: "biologia HL",
                            biologySL: "biologia SL"
                        },
                        chemistry: {
                            chemistryHL: "chemia HL",
                            chemistrySL: "chemia SL"
                        },
                        physics: {
                            physicsHL: "fizyka HL",
                            physicsSL: "fizyka SL"
                        },
                        history: {
                            historyHL: "historia HL",
                            historySL: "historia SL"
                        },
                        geography: {
                            geographyHL: "geografia HL",
                            geographySL: "geografia SL"
                        },
                        french: {
                            frenchHL: "francuski HL",
                            frenchSL: "francuski SL"
                        },
                        italian: {
                            italianHL: "włoski HL",
                            italianSL: "włoski SL"
                        },
                        russian: {
                            russianHL: "rosyjski HL",
                            russianSL: "rosyjski SL"
                        },
                        spanish: {
                            spanishHL: "hiszpański HL",
                            spanishSL: "hiszpański SL"
                        },
                        computerScience: {
                            computerScienceHL: "informatyka HL",
                            computerScienceSL: "informatyka SL"
                        },
                        english: {
                            englishHL: "angielski HL",
                            englishSL: "angielski SL"
                        },
                    },
                    exams: {
                        title: "Egzamin",
                        tsa: "TSA",
                        fineArt: "Fine art practical",
                        bmat: "BMAT",
                        ucat: "UCAT",
                        cat: "CAT",
                        cae: "CAE",
                        cpe: "CPE",
                        celi345: "CELI 3, 4, 5",
                        ctmua: "CTMUA",
                        dsd1: "DSD 1",
                        dsd2: "DSD 2",
                        delf: "DELF",
                        dele: "DELE",
                        testDaf: "Test DaF",
                        elat: "ELAT",
                        engaa: "ENGAA",
                        gmat: "GMAT",
                        gre: "GRE",
                        hat: "HAT",
                        ielts: "IELTS",
                        mat: "MAT",
                        bocconi: "Test Bocconi",
                        lnat: "LNAT",
                        ompt: "OMPT",
                        pearson: "PEARSON",
                        nsaa: "NSAA",
                        sat: "SAT",
                        step: "STEP",
                        step123: "STEP 1,2,3",
                        tmua: "TMUA",
                        toefl: "TOEFL",
                        mlat: "MLAT",
                        olat: "OLAT",
                        imat: "IMAT",
                        ukat: "UKAT",
                        pat: "PAT",
                        philosophy: "Philosophy Test",
                        musicPerformance: "Music performance test",
                        stGallen: "Test St. Gallen",
                        torfl: "TORFL",
                        haa: "HAA (Cambridge)"
                    },
                    additional: {
                        title: "Pozostałe",
                        primarySchoolExam: "egzamin 8-klasisty",
                        middleSchool: "szkoła średnia",
                        olympicsPreparations: "przygotowania do olimpiady",
                        nativeSpeaker: "native speaker",
                        conversations: "konwersacje",
                        languageExams: "egzaminy językowe",
                        beginner: "poziom A1-A2 (Begginer, Elementary)",
                        intermediate: "poziom B1-B2 (Intermediate)",
                        advanced: "poziom C1-C2 (Advanced, Proficency)",
                    }
                }
            },
            thank_you: {
                title: "Dziękujemy za wypełnienie",
                info: 'Twoje zgłoszenie zostało do nas wysłane. Następny krok to rozmowa z naszym rekruterem. W ciągu najbliższych dwóch dni roboczych odezwiemy się do Ciebie, aby umówić się na spotkanie online i porozmawiać o Twoim doświadczeniu i wyjaśnić zasady współpracy na Platformie EduCat. Do usłyszenia!'
            },
            alerts: {
                registrationSendingFailure: "Wystąpił błąd podczas wysyłania ankiety rejestracyjnej, spróbuj ponownie albo skontaktuj się z Administratorem",
                registrationSendingSuccess: "Ankieta rejestracyjna została wysłana. Po zatwierdzeniu rejestracji przez Adminainistratora zostanie wysłany email z potwierdzeniem dostępu do następnych kroków.",
                fetchMentorError: "Pobranie danych użytkownika nie powiodło się, spróbuj ponownie albo skontaktuj sie z Administratorem."
            }
        },
        onboarding: {
            maxApplicantsAdditionalInformation: "* maksymalna liczba aplikantów do przyjęcia w ramach pakietu",
            priceRecalculatingInfo: "* cena podana we wcześniejszym kroku za godzinę próbną automatycznie nalicza cenę za pakiety",
            formTableHeader: {
                helpRange: "Zakres pomocy / usługi",
                eduCatPrice: "Cena netto na Platformie EduCat",
                hourPackages: "Pakiety godzin",
                nettoWithoutDiscount: "Cena netto bez rabatu*",
                clientDiscount: "Rabat dla klienta",
                nettoAfterDiscount: "Cena netto po rabacie",
                packages: "Pakiety",
                servicesInPackage: "Usługi w ramach pakietu",
                maxApplicantNo: "Max. liczba aplikantów*",
                eduCatCommission: "Prowizja EduCat",
                mentorGrossPrice: "Zarobek brutto Mentora w PLN",
                vat: "Podatek Vat",
                platformPrice: "Cena na platformie",
                platformGrossPrice: "Cena na platformie brutto"
            },
            confirmation_page: {
                title: "Ankieta onboardingowa",
                registrationBasic: {
                    title: "Oczekiwanie na zatwierdzenie rejestracji",
                    confirmInformation: "Twoja rejestracja jest w trakcie procesu akceptacji. Po zatwierdzeniu przez Administratora, zostanie wysłany e-mail z potwierdzeniem umożliwiający jej kontynuowanie."
                },
                registration: {
                    title: "Oczekiwanie na zatwierdzenie rejestracji przez administratora",
                    confirmInformation: "Twoja rejestracja uzupełniająca jest w trakcie procesu akceptacji. Po zatwierdzeniu przez Administratora, zostanie wysłany e-mail z potwierdzeniem."
                },
                onboarding: {
                    title: "Oczekiwanie na zatwierdzenie onboardingu",
                    confirmInformation: "Twoja ankieta onboardingowa jest w trakcie procesu akceptacji. Po zatwierdzeniu przez Administratora, zostanie wysłany e-mail z potwierdzeniem."
                }
            },
            onboarding_information: {
                title: "Ankieta onboardingowa",
                formTitle: "Informacje Ogólne",
                additionalInfo: "Aby rozpocząć współpracę, potrzebujemy od Ciebie kilku informacji do celów podatkowych, aby móc w przyszłości się z Tobą rozliczać za przeprowadzone usługi. Odpowiedz na poniższe pytania, a w razie wątpliwości daj nam znać!",
                formControls: {
                    placeholder: 'Wpisz tutaj',
                    nationality: {
                        label: "Jakiej jesteś narodowości?",
                        polish: "Polskiej",
                        other: "Innej"
                    },
                    residency: {
                        label: "Czy jesteś rezydentem podatkowym innego kraju niż Polska?",
                        yes: "Tak",
                        no: "Nie",
                        notSure: "Nie wiem"
                    },
                    businessOwner: {
                        label: "Czy masz działalność gospodarczą?",
                        yes: "Mam działalność",
                        no: "Nie mam działalności"
                    },
                    over26: {
                        label: "Czy masz 26 lat lub wyżej?",
                        overOrEqual: "26 lat lub więcej",
                        under: "poniżej 26 lat"
                    },
                    student: {
                        label: "Czy jesteś studentem?",
                        yes: "Jestem studentem",
                        no: "Nie jestem studentem"
                    },
                    otherEmployment: {
                        label: "Inne miejsca zatrudnienia"
                    },
                    bankDataLabel: "Podaj numer swojego konta bankowego, na które chciałbyś dostawać swoją pensję.",
                    bankAccountNumber: {
                        label: "Nr konta"
                    },
                    bankData: {
                        label: "Dane banku"
                    },
                }
            },
            hourly_rates: {
                title: "Ankieta onboardingowa",
                formTitle: "Stawki za godziny",
                formSubtitle: "W zależności od tego, jakie usługi zaznaczyłeś w ankiecie rejestracyjnej, podaj jakie stawki chcesz ustalić za poszczególne usługi. EduCat pobiera prowizję 60%, która konieczna jest ze względu na utrzymanie działania platformy.",
                tooltipLabel: "Szacowana czasochłonność danej usługi wynosi {{expectedLength}}",
                tooltip: "Godzina możliwa do wykupienia przez Aplikanta tylko raz z danym mentorem. Jest to okazja do zareklamowania swoich dalszych usług",
                name: {
                    'consultation_trial': "Godzina Próbna",
                    'consultation': "Konsultacja"
                }
            },
            service_packages: {
                title: "Ankieta onboardingowa",
                formTitle: "Pakiety usług",
                formSubtitle: "Na platformie Klienci mają dostęp do pakietów wielu usług z rabatami 15% Zaznacz, które pakiety usług chcesz wykonywać. EduCat pobiera prowizję 60%, która konieczna jest ze względu na utrzymanie działania platformy.",
            },
            hour_packages: {
                title: "Ankieta onboardingowa",
                formTitle: "Pakiety godzin",
                formSubtitle: "Na platformie Klienci mają dostęp do pakietów godzin z danym mentorem. Zaznacz, które pakiety godzin chcesz wykonywać. EduCat pobiera prowizję 60%, która konieczna jest ze względu na utrzymanie działania platformy.",
            },
            free_help_packages: {
                title: "Ankieta onboardingowa",
                formTitle: "Pakiet pomocy bezpłatnej",
                formSubtitle: `Pomoc bezpłatna obejmuje przygotowanie do aplikacji tylko na uczelnie partnerskie EduCat. W ramach pakietu pomocy bezpłatnej jest 5 godzin z mentorem.
        Co dostajesz za pracę z aplikantami korzystającymi z pomocy bezpłatnej?

        - uczestnictwo w szkoleniach
        - doświadczenie zawodowe w pracy z klientem
        - społeczność międzynarodowych studentów EduCat
        - dodatkowe zarobki (jeśli Twój aplikant dostanie się i wyjedzie na studia na uczelni partnerskiej)

        Zaznacz, czy chcesz wykonywać ten pakiet oraz ilu aplikantów bezpłatnych chcesz maksymalnie przyjąć pod opiekę w danym roku aplikacyjnym (od września do września).
        `,
            },
            application_exam_prices: {
                title: "Ankieta onboardingowa",
                formTitle: "Usługi Mentora",
                formSubtitle: "W zależności od tego, jakie usługi zaznaczyłeś w ankiecie rejestracyjnej, podaj jakie stawki chcesz ustalić za poszczególne usługi. EduCat pobiera prowizję 60%, która konieczna jest ze względu na utrzymanie działania platformy.",
            },
            thank_you: {
                title: "Dziękujemy za wypełnienie!",
                line1:
                    "Twoja ankieta do nas dotarła. Teraz przygotowujemy dla Ciebie umowę, którą otrzymasz od nas poprzez system DocuSign. Po jej podpisaniu dostaniesz pełny dostęp do Platformy wraz z instrukcją jej obsługiwania.",
                line2: "Zwykle przygotowanie umowy zajmuje nam nie więcej niż 2 dni robocze :)",
                greeting: "Do usłyszenia!",
                signature: "Zespół EduCat",
            },
            alerts: {
                onboardingSendingFailure: "Wystąpił błąd podczas wysyłania ankiety onboardingowej, spróbuj ponownie albo skontaktuj się z Administratorem",
                onboardingSendingSuccess: "Dane podatkowe użytkownika zostały wysłane.",
                fetchMentorError: "Pobranie danych użytkownika nie powiodło się, spróbuj ponownie albo skontaktuj sie z Administratorem.",
                onboardingPackagePricesCalculationFailure: "Przeliczenie cen pakietów się nie powiodło, spróbuj ponownie albo skontaktuj się z Administratorem",
                onboardingPackagePricesCalculationSuccess: "Przeliczono ceny pakietów",
                createServiceDefinitionFailure: "Nie udało się zapisać zmian, spróbuj ponownie lub skontaktuj się z Administratorem",
                createServiceDefinitionSuccess: "Zmiany zapisane poprawnie.",
            },
        },
    },
    countries: {
        ad: 'Andora',
        ae: 'Zjednoczone Emiraty Arabskie',
        af: 'Afganistan',
        ag: 'Antigua i Barbuda',
        ai: 'Anguilla',
        al: 'Albania',
        am: 'Armenia',
        an: 'Antyle Holenderskie',
        ao: 'Angola',
        ar: 'Argentyna',
        as: 'Samoa Amerykańska',
        at: 'Austria',
        au: 'Australia',
        aw: 'Aruba',
        ax: 'Wyspy Alandzkie',
        az: 'Azerbejdżan',
        ba: 'Bośnia i Hercegowina',
        bb: 'Barbados',
        bd: 'Bangladesz',
        be: 'Belgia',
        bf: 'Burkina Faso',
        bg: 'Bułgaria',
        bh: 'Bahrajn',
        bi: 'Burundi',
        bj: 'Benin',
        bm: 'Bermudy',
        bn: 'Brunei',
        bo: 'Boliwia',
        br: 'Brazylia',
        bs: 'Bahamy',
        bt: 'Bhutan',
        bv: 'Wyspa Bouveta',
        bw: 'Botswana',
        by: 'Białoruś',
        bz: 'Belize',
        ca: 'Kanada',
        cc: 'Wyspy Kokosowe',
        cd: 'Demokratyczna Republika Konga',
        cf: 'Republika Środkowoafrykańska',
        cg: 'Demokratyczne Republika Konga',
        ch: 'Szwajcaria',
        ci: 'Wybrzeże Kości Słoniowej',
        ck: 'Wyspy Cooka',
        cl: 'Chile',
        cm: 'Kamerun',
        cn: 'Chiny',
        co: 'Kolumbia',
        cr: 'Kostaryka',
        ct: 'Canton i Enderbury',
        cu: 'Kuba',
        cv: 'Wyspy Zielonego Przylądka',
        cx: 'Wyspa Bożego Narodzenia',
        cy: 'Cypr',
        cz: 'Czechy',
        de: 'Niemcy',
        dj: 'Dżibuti',
        dk: 'Dania',
        dm: 'Dominika',
        do: 'Dominikana',
        dz: 'Algieria',
        ec: 'Ekwador',
        ee: 'Estonia',
        eg: 'Egipt',
        eh: 'Ghana',
        er: 'Erytrea',
        es: 'Hiszpania',
        et: 'Etiopia',
        fi: 'Finlandia',
        fj: 'Fidżi',
        fk: 'Falklandy (Malwiny)',
        fm: 'Mikronezja',
        fo: 'Wysypy Owcze',
        fr: 'Francja',
        ga: 'Gabon',
        gb: 'Wielka Brytania',
        gd: 'Grenada',
        ge: 'Gruzja',
        gf: 'Gujana Francuska',
        gg: 'Guernsey',
        gh: 'Ghana',
        gi: 'Gibraltar',
        gl: 'Grenlandia',
        gm: 'Gambia',
        gn: 'Gwinea',
        gp: 'Gwadelupa',
        gq: 'Gwinea Równikowa',
        gr: 'Grecja',
        gs: 'Georgia Południowa i Sandwich Południowy',
        gt: 'Gwatemala',
        gu: 'Guam',
        gw: 'Gwinea Bissau',
        gy: 'Gujana',
        hk: 'Hongkong',
        hm: 'Wyspy Heard i McDonalda',
        hn: 'Honduras',
        hr: 'Chorwacja',
        ht: 'Haiti',
        hu: 'Węgry',
        id: 'Indonezja',
        ie: 'Irlandia',
        il: 'Izrael',
        im: 'Wyspa Man',
        in: 'Indie',
        iq: 'Irak',
        ir: 'Iran',
        is: 'Islandia',
        it: 'Włochy',
        io: 'Brytyjskie Terytorium Oceanu Indyjskiego',
        je: 'Jersey',
        jm: 'Jamajka',
        jo: 'Jordan',
        jp: 'Japonia',
        jt: 'Johnston',
        ke: 'Kenia',
        kg: 'Kirgistan',
        kh: 'Kambodża',
        ki: 'Kiribati',
        km: 'Komory',
        kn: 'Saint Kitts i Nevis',
        kp: 'Korea Północna',
        kr: 'Korea Południowa',
        kw: 'Kuwejt',
        ky: 'Kajmany',
        kz: 'Kazachstan',
        la: 'Laos',
        lb: 'Liban',
        lc: 'Saint Lucia',
        li: 'Liechtenstein',
        lk: 'Sri Lanka',
        lr: 'Liberia',
        ls: 'Lesotho',
        lt: 'Litwa',
        lu: 'Luksemburg',
        lv: 'Łotwa',
        ly: 'Libia',
        ma: 'Maroko',
        mc: 'Monako',
        md: 'Mołdawia',
        me: 'Czarnogóra',
        mg: 'Madagaska',
        mh: 'Wyspy Marshalla',
        mi: 'Midway',
        mk: 'Macedonia Północna',
        ml: 'Mali',
        mm: 'Mjanma (Birma)',
        mn: 'Mongolia',
        mp: 'Mariany Północne',
        mq: 'Martynika',
        mr: 'Mauretania',
        ms: 'Montserrat',
        mt: 'Malta',
        mu: 'Mauritius',
        mv: 'Malediwy',
        mw: 'Malawi',
        mx: 'Meksyk',
        my: 'Malezja',
        mz: 'Mozambik',
        na: 'Namibia',
        nc: 'Nowa Kaledonia',
        ne: 'Niger',
        nf: 'Norfolk',
        ng: 'Nigeria',
        ni: 'Nikaragua',
        nl: 'Holandia',
        no: 'Norwegia',
        np: 'Nepal',
        nq: 'Ziemia Królowej Maud',
        nr: 'Nauru',
        nu: 'Niue',
        nz: 'Nowa Zelandia',
        om: 'Oman',
        pa: 'Panama',
        pe: 'Paru',
        pf: 'Polinezja Francuska',
        pg: 'Papua-Nowa Gwinea',
        ph: 'Filipiny',
        pk: 'Pakistan',
        pl: 'Polska',
        pn: 'Pitcairn',
        pr: 'Portoryko',
        ps: 'Palestyna',
        pt: 'Portugalia',
        pu: 'Wyspy USA na Oceanie Spokojnym',
        pw: 'Palau',
        py: 'Paragwaj',
        qa: 'Katar',
        re: 'Reunion',
        ro: 'Rumunia',
        rs: 'Serbia',
        ru: 'Rosja',
        rw: 'Rwanda',
        sa: 'Arabia Saudyjska',
        sb: 'Wyspy Salomona',
        sc: 'Seszele',
        sd: 'Sudan',
        se: 'Szwecja',
        sg: 'Singapur',
        sh: 'Wyspa Świętej Heleny',
        si: 'Słowenia',
        sj: 'Svalbard i Jan Mayen',
        sk: 'Słowacja',
        sl: 'Sierra Leone',
        sm: 'San Marino',
        sn: 'Senegal',
        so: 'Somalia',
        sr: 'Surinam',
        ss: 'Sudan Południowy',
        st: 'Wyspy Świętego Tomasza i Książęca',
        sv: 'Salwador',
        sy: 'Syria',
        sz: 'Eswatini',
        tc: 'Turks i Caicos',
        td: 'Czad',
        tg: 'Togo',
        th: 'Tajlandia',
        tj: 'Tadżykistan',
        tk: 'Tokelau',
        tl: 'Timor Wschodni',
        tm: 'Turkmenistan',
        tn: 'Tunezja',
        to: 'Tonga',
        tp: 'Timor Wschodni',
        tr: 'Turcja',
        tt: 'Trynidad i Tobago',
        tv: 'Tuvalu',
        tw: 'Tajwan',
        tz: 'Tanzania',
        ua: 'Ukraina',
        ug: 'Uganda',
        um: 'Dalekie Wyspy Mniejsze Stanów Zjednoczonych',
        us: 'Stany Zjednoczone',
        uy: 'Urugwaj',
        uz: 'Uzbekistan',
        va: 'Stolica Apostolska',
        vc: 'Saint Vincent i Grenadyny',
        ve: 'Wenezuela',
        vg: 'Brytyjskie Wyspy Dziewicze',
        vi: 'Wyspy Dziewicze Stanów Zjednoczonych',
        vn: 'Wietnam',
        vu: 'Vanuatu',
        wf: 'Wallis i Futuna ',
        wk: 'Wake',
        ws: 'Samoa',
        xa: 'Palestyna',
        xf: 'Wyspy Alandzkie',
        xk: 'Kosowo',
        yd: 'Demokratyczna Republika Jemenu',
        ye: 'Jemen',
        yt: 'Majotta',
        za: 'Afryka Południowa',
        zm: 'Zambia',
        zr: 'Zair',
        zw: 'Zimbabwe',
    },
    language: {
        de: 'niemiecki',
        pl: 'polski',
        en: 'angielski'
    },
    maintenance: {
        pageNotFound: 'Błąd - nie znaleziono strony'
    },
    schedules: {
        title: "Harmonogramy",
        filters: {
            country: "Kraj",
            school: "Uczelnia",
            realm: "Kierunek",
        },
        addSchedule: {
            title: "Nowy harmonogram",
            description: "Stwórz nowy harmonogram na uczelnię i kierunek, którego potrzebujesz. Zadbaj o to, aby wszystkie daty były poprawne.",
            add: "Dodaj nowy",
            form: {
                placeholders: {
                    allOptions: 'Wszystkie',
                    applicant: 'Aplikant',
                    typeOption: 'Zacznij wpisywać nazwę'
                },
                labels: {
                    country: 'Kraj',
                    school: 'Uczelnia',
                    studyField: 'Kierunek'
                }
            }
        },
        noSchedule: {
            title: "Nie znaleziono harmonogramów",
            description: "Niestety, na wybrany przez Ciebie kierunek nie ma jeszcze harmonogramu. Musisz stworzyć go od zera. Sprawdź dokładnie wszystkie terminy związane z aplikacją, aby żaden ważny termin Wam nie uciekł."
        },
        editSchedule: "Edytuj harmonogram",
        addScheduleModal: {
            title: "Dodaj harmonogram",
            preview: "Podgląd",
            previewTitle: "Podgląd harmonogramu {{school}}",
            previewDescription: "Przewijaj harmonogram horyzontalnie aby zobaczyć więcej.",
            backToEdit: "Powrót do edycji",
            accept: "Potwierdź",
            addEvent: "+ Dodaj wydarzenia",
            event: 'Wydarzenie',
            eventStart: 'Początek',
            eventEnd: 'Koniec',
            scheduleAdded: 'Harmonogram został dodany',
            scheduleDetailsUpdated: 'Szczegóły harmonogramu zostały zaktualizowane'
        },
        table: {
            country: "Kraj",
            school: "Uczelnia",
            realm: "Kierunek",
            moreResults: "Zobacz więcej zadań",
        },
        noData: "Nie znaleziono harmonogramów",
        noDataInfo:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
        details: {
            title: "Harmonogram",
            table: {
                events: "Wydarzenia",
                start: "Początek",
                end: "Koniec",
                delete: "Usuń",
            },
            assignToApplicant: {
                assignBtn: "Przypisz do aplikanta",
                confirm: "Potwierdź",
                title: "Przypisz harmonogram do aplikanta {{school}}",
                labels: {
                    applicant: "Aplikant",
                    addToEducat: "Dodaj zaktualizowany plan dla {{school}} na kierunek ABC {{studyField}} do bazy EduCat",
                },
                scheduleAssignedToApplicant: 'Harmonogram został przypisany do aplikanta'
            },
            edit: "Edytuj",
        },
    },
    alerts: {
        saveSuccessful: 'Zmiany zostały zapisane!',
        baseError: 'Coś poszło nie tak. Spróbuj ponownie później',
        noAccessError: "Nie posiadasz odpowiednich uprawnień aby zalogować się do panelu.",
        authError: "Niepoprawny login lub hasło. Sprawdź poprawność wpisanych danych i spróbuj jeszcze raz",
        loginError: "Podczas logowania wystąpił błąd:",
        tryAgain: "Spróbuj jeszcze raz.",
        tokenRefreshed: "Twoja sesja została odnowiona",
        unknown: "Wystąpił nieznany błąd."
    },
    dateRangePicker: {
        config: {
            previousMonth: 'Zeszły miesiąc',
            lastWeek: 'Zeszły tydzień',
            today: 'Dziś',
            currentMonth: 'Ten miesiąc',
        },
    },
    badge: {
        confirm: {
            yes: 'TAK',
            no: 'NIE',
        }
    }
};

export default pl;
