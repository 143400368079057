import React from "react";
import {of, Subscription} from "rxjs";
import {fixInjectedProperties, lazyInject} from "../../../ioc";
import {IAlertManagerService} from "../../../service/alertManagerService";
import {IInquirySubjectOutput, Loader, LoaderType, Translation, updateInquiryAPI} from "educat-common-web";
import {WithTranslation, withTranslation} from "react-i18next";
import moment from "moment";
import InquiryViewEditModal, {InquiryViewEditModalType} from "../InquiryViewEditModal";
import ConfirmActionButton from "./ConfirmActionButton";
import InquiryStatusBadge from "../InquiryStatusBadge";
import {catchError, map} from "rxjs/operators";
import {InquiryValues, ISettleInquiryPayload, settleMultipleInquiriesAPI} from "../../../api/settleMultipleInquiries";


interface IInquiryListTableConnectedProps {}

interface IInquiryListTableExternalProps {
    inquiries: any[];
    isProcessing: boolean;
    authToken: string | null;
    getInquiriesList?: () => void;
}

interface IInquiryListTableProps extends IInquiryListTableConnectedProps,
    IInquiryListTableExternalProps,
    WithTranslation {
}

interface IInquiryListTableState {
    settleInquiryList: string[];
    modalInquiry: any | null;
    modalInquiryType: InquiryViewEditModalType | null;
}

class InquiryListTable extends React.Component<IInquiryListTableProps, IInquiryListTableState> {
    readonly subscriptions: Subscription[] = [];
    @lazyInject('AlertManagerService') private alertManager: IAlertManagerService;

    constructor(props: IInquiryListTableProps) {
        super(props);

        this.state = {
            settleInquiryList: [],
            modalInquiry: null,
            modalInquiryType: null,
        };

        fixInjectedProperties(this);
    }

    componentDidMount(): void {}

    componentDidUpdate(
        prevProps: Readonly<IInquiryListTableProps>,
        prevState: Readonly<IInquiryListTableState>,
        snapshot?: any
    ): void {}

    componentWillUnmount() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    render() {
        if ((!this.props.inquiries ||
                !Array.isArray(this.props.inquiries)) ||
            (this.props.inquiries.length === 0 && !this.props.isProcessing)) {
            return <p><Translation text={'inquiries.inquiryList.table.noData'}/></p>;
        }
        return (
            <React.Fragment>
                <Loader type={LoaderType.Local} showLoader={this.props.isProcessing}/>
                <div className="table-container mb-3">
                    <table className="data-table default-table overflow-scroll">
                        <thead>
                        <tr>
                            <th className="highlight-cell"/>
                            <th className="highlight-cell" colSpan={5}>
                                <Translation text={'inquiries.inquiryList.table.inquiryData'}/>
                            </th>
                            <th className="highlight-cell" colSpan={4}>
                                <Translation text={'inquiries.inquiryList.table.subjectData'}/>
                            </th>
                            <th className="highlight-cell" colSpan={5}>
                                <Translation text={'inquiries.inquiryList.table.financialData'}/>
                            </th>
                        </tr>
                        <tr>
                            <th className="highlight-cell"/>
                            <th className="highlight-cell">
                                <Translation text={'inquiries.inquiryList.table.createDate'}/>
                            </th>
                            <th className="highlight-cell">
                                <Translation text={'inquiries.inquiryList.table.purchaseDate'}/>
                            </th>
                            <th className="highlight-cell">
                                <Translation text={'inquiries.inquiryList.table.inquiryNo'}/>
                            </th>
                            <th className="highlight-cell">
                                <Translation text={'inquiries.inquiryList.table.inquiryDateRange'}/>
                            </th>
                            <th className="highlight-cell">
                                <Translation text={'inquiries.inquiryList.table.status'}/>
                            </th>
                            <th className="highlight-cell">
                                <Translation text={'inquiries.inquiryList.table.name'}/>
                            </th>
                            <th className="highlight-cell">
                                <Translation text={'inquiries.inquiryList.table.phone'}/>
                            </th>
                            <th className="highlight-cell">
                                <Translation text={'inquiries.inquiryList.table.email'}/>
                            </th>
                            <th className="highlight-cell">
                                <Translation text={'inquiries.inquiryList.table.school'}/>
                            </th>
                            <th className="highlight-cell">
                                <Translation text={'inquiries.inquiryList.table.inquiryPremium'}/>
                            </th>
                            <th className="highlight-cell">
                                <Translation text={'inquiries.inquiryList.table.axaFee'}/>
                            </th>
                            <th className="highlight-cell">
                                <Translation text={'inquiries.inquiryList.table.educatFee'}/>
                            </th>
                            <th className="highlight-cell">
                                <Translation text={'inquiries.inquiryList.table.isSettled'}/>
                            </th>
                            <th className="highlight-cell"/>
                        </tr>
                        </thead>
                        <tbody>
                        {this.renderTableRows()}
                        </tbody>
                    </table>
                </div>
                <ConfirmActionButton disabled={!this.state.settleInquiryList.length}
                                     onConfirm={this.massInquirySettle}
                                     btnClass={'btn btn-theme btn-small'}
                                     translationKey={'inquiries.inquiryList.actions.btnMassSettle'}/>

                { this.state.modalInquiry && <InquiryViewEditModal isModalVisible={!!this.state.modalInquiry}
                                                                   closeModal={() => this.toggleInquiryModal(null)}
                                                                   inquiry={this.state.modalInquiry}
                                                                   type={this.state.modalInquiryType}
                                                                   getInquiriesList={this.props.getInquiriesList}
                                                                   changeModalType={this.onChangeModalType}/>
                }
            </React.Fragment>
        )
    }

    private renderTableRows() {
        const rows: any[] = [];

        const renderSettlementBadge = (isSettled: boolean) => {
            return (
                <span className={`badge badge-confirm ${isSettled ? 'yes' : 'no'}`}>
                    <Translation text={`badge.confirm.${isSettled ? 'yes' : 'no'}`} />
                </span>
            );
        }

        const renderSettleButton = (inquiry: any) => {
            let disabled: boolean = false;
            if (!inquiry.offers || !Array.isArray(inquiry.offers) || !inquiry.offers.length || inquiry.settled) disabled = true;

            const status = inquiry.offers[0].status;
            if (status === 'status_draft' || status === 'status_expired_draft') disabled = true;

            return (
                <li>
                    <ConfirmActionButton onConfirm={() => this.settleInquiry(inquiry)}
                                         disabled={disabled}
                                         btnClass={'text-nowrap'}
                                         translationKey={'inquiries.inquiryList.table.actions.settle'}/>
                </li>
            )
        }

        const renderFeeCells = (acceptedOffer: any) => {
            if (!acceptedOffer || acceptedOffer.status === 'status_draft' || acceptedOffer.status === 'status_expired_draft') {
                return (
                    <>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </>
                )
            }

            return (
                <>
                    <td className="text-nowrap">{`${(acceptedOffer.premium?.amount / 100).toFixed(2)} EUR`}</td>
                    <td className="text-nowrap">{`${(acceptedOffer.axaPremium?.amount / 100).toFixed(2)} EUR`}</td>
                    <td className="text-nowrap">{`${(acceptedOffer.systemFee?.amount / 100).toFixed(2)} EUR`}</td>
                </>
            )
        }

        this.props.inquiries.map((inquiry: any) => {
            return rows.push((
                <tr key={inquiry.id}>
                    <td><input type="checkbox" disabled={inquiry.settled}
                               onClick={() => this.updateMassSettlementList(inquiry)}/></td>
                    <td className="text-nowrap">{moment(inquiry.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                    <td className="text-nowrap">
                        {inquiry.acceptedOffer?.paidAt ? moment(inquiry.acceptedOffer?.paidAt).format('DD/MM/YYYY HH:mm') : '-'}
                    </td>
                    <td>{inquiry.acceptedOffer?.reference || '-'}</td>
                    <td className="text-nowrap">{moment(inquiry.from).format('DD/MM/YYYY')} - {moment(inquiry.to).format('DD/MM/YYYY')}</td>
                    <td><InquiryStatusBadge status={inquiry.offers[0]?.status}/></td>
                    <td className="text-nowrap">{inquiry.inquirySubjects.length ? `${inquiry.inquirySubjects[0].firstName} ${inquiry.inquirySubjects[0].lastName}` : ''}</td>
                    <td className="text-nowrap">{inquiry.inquirySubjects.length ? inquiry.inquirySubjects[0].phone : '-'}</td>
                    <td className="text-nowrap">{inquiry.guestEmail || '-'}</td>
                    <td className="text-nowrap">{inquiry.schoolName}</td>
                    { renderFeeCells(inquiry.acceptedOffer) }

                    <td className="text-nowrap">{renderSettlementBadge(inquiry.settled)}</td>
                    <td>
                        <ul className="inquiry-actions-container">
                            <li>
                                <a onClick={() => this.toggleInquiryModal(inquiry, InquiryViewEditModalType.View)}>
                                    <Translation text="inquiries.inquiryList.table.actions.details"/>
                                </a>
                            </li>
                            <li>
                                <a className={!this.isInsuranceDateInFuture(inquiry.from) ? "disabled" : ''}
                                   onClick={() => this.toggleInquiryModal(inquiry, InquiryViewEditModalType.Edit)}>
                                    <Translation text="inquiries.inquiryList.table.actions.edit"/>
                                </a>
                            </li>

                            {renderSettleButton(inquiry)}

                        </ul>
                    </td>
                </tr>
            ))
        });
        return rows;
    }

    private toggleInquiryModal = (inquiry: any | null, type?: InquiryViewEditModalType) => {
        this.setState({
            modalInquiry: inquiry,
            modalInquiryType: type || null,
        })
    }

    private onChangeModalType = (type: InquiryViewEditModalType) => {
        this.setState({modalInquiryType: type})
    }

    private updateMassSettlementList = (inquiryId: any) => {
        const clonedList = [...this.state.settleInquiryList];
        const index: number = clonedList.indexOf(inquiryId, 0);
        if (index > -1) {
            clonedList.splice(index, 1);
        } else {
            clonedList.push(inquiryId);
        }
        this.setState({
            settleInquiryList: clonedList,
        })
    }

    private settleInquiry = (inquiry: any) => {
        if (!inquiry) {
            return;
        }

        const inquirySubject: typeof IInquirySubjectOutput = inquiry.inquirySubjects[0];
        const payload = {
            inquirySubjects: inquiry.inquirySubjects,
            applicantId: inquiry.applicant?.id || null,
            mentorId: inquiry?.mentor?.id || null,
            inquiryVariantId: inquiry.inquiryVariant.id,
            settled: true,
            destination: inquiry.destination,
            source: inquiry?.source || null,
            guestEmail: inquirySubject.email,
            visitDate: inquiry.visitDate,
            alcoholClause: inquiry.alcoholClause,
            physicalWork: inquiry.physicalWork,
            schoolName: inquiry.school,
            tripReason: inquiry.tripReason,
            from: inquiry.from,
            to: inquiry.to,
            timezone: inquiry.timezone
        };

        this.subscriptions.push(
            updateInquiryAPI(this.props.authToken, inquiry.id, payload).pipe(
                map(() => {
                    this.alertManager.addAlert('inquiries.inquiryList.table.singleInquirySettled');
                    if (this.props.getInquiriesList) {
                        this.props.getInquiriesList();
                    }
                }),
                catchError((error: any) => {
                    this.alertManager.handleApiError(error);
                    return of();
                })
            ).subscribe()
        )
    }

    private massInquirySettle = () => {
        const values = this.state.settleInquiryList.reduce((acc: InquiryValues, item: any) => {
            acc[item.id] = true;
            return acc;
        }, {} as { [key: string]: boolean });

        const payload: ISettleInquiryPayload = {
            values: values
        }

        this.subscriptions.push(
            settleMultipleInquiriesAPI(this.props.authToken, payload).pipe(
                map(() => {
                    this.alertManager.addAlert('inquiries.inquiryList.table.multipleInquiresSettled');
                    this.setState({settleInquiryList: []});
                    if (this.props.getInquiriesList) {
                        this.props.getInquiriesList();
                    }
                }),
                catchError((error: any) => {
                    this.alertManager.handleApiError(error);
                    return of();
                })
            ).subscribe()
        )
    }

    private isInsuranceDateInFuture = (insuranceDateString: string) => {
        const insuranceDate = new Date(insuranceDateString),
            currentDate = new Date();

        return insuranceDate > currentDate;
    }
}

export default withTranslation()(InquiryListTable);
