import React, {useEffect, useState} from 'react';
import {
    Translation,
    handleApiError,
    addAlert,
    AlertObject,
    authTokenSelector,
    RestQueryParams,
    Loader,
    LoaderType,
    MultiSelect,
    MultiSelectType
} from 'educat-common-web';
import {catchError, map} from "rxjs/operators";
import {of, Subscription} from "rxjs";
import {useDispatch, useSelector} from "react-redux";
import {getUsersAPI} from "../../../../../api/getUsersAPI";
import {useTranslation} from "react-i18next";

interface IInquiryEmailProps {
    inquiryEmail: string;
    updateInquiry: (emailValue: string | undefined) => void;
}

const InquiryEmail: React.FC<IInquiryEmailProps> = ({inquiryEmail, updateInquiry}) => {
    const {t} = useTranslation(),
        dispatch = useDispatch(),
        authToken: string | null = useSelector(authTokenSelector),
        [isLoading, setIsLoading] = useState<boolean>(false),
        [selectedUsername, setSelectedUsername] = useState<{value: string; label: string} | null>({value: inquiryEmail, label: inquiryEmail}),
        [usersList, setUsersList] = useState<any[]>([]);

    const subscriptions: Subscription[] = [];

    useEffect(() => {
        getUsersList();

        return() => {
            if (subscriptions) {
                subscriptions.forEach(subscription => {
                    if (subscription.unsubscribe) {
                        subscription.unsubscribe();
                    }
                });
            }
        }
    }, []);

    const getUsersList = (username?: string) => {
        let params = new RestQueryParams()
            .add('properties[]', 'login')
            .add('properties[account][]', 'firstName')
            .add('properties[account][]', 'lastName');

        if (username) {
            params = params.add('login[]', username);
        }

        subscriptions.push(
            getUsersAPI(authToken, params).pipe(
                map((resp) => {
                    const selectList = resp['hydra:member'].map((item: any) => {
                        return {
                            value: item.login,
                            label: item.login,
                            additionalData: `${item.account.firstName} ${item.account.lastName}`
                        }
                    })

                    setUsersList(selectList);
                }),
                catchError((error) => {
                    const errorObj = handleApiError(error) as typeof AlertObject;
                    setIsLoading(false);
                    return of(dispatch(addAlert(errorObj)))
                })
            ).subscribe()
        )
    }

    return (
        <div className='inquiry-email-edit mb-5'>
            <h2 className="subtitle mb-2">
                <Translation text={'inquiries.inquiryList.editModal.editEmail.title'}/>
            </h2>

            <div className="mb-5">
                <MultiSelect
                    multiselectType={MultiSelectType.SINGLE}
                    handleChange={(e: any) => setSelectedUsername(e)}
                    options={usersList}
                    name={'select_users'}
                    placeholder={t('inquiries.inquiryList.editModal.editEmail.selectEmail')}
                    value={selectedUsername}
                    handleInputChange={(data: any) => getUsersList(data)}
                    isComponentCustom={true}
                    isCustomUsernameOption={true}
                    isClearable={true}
                />
            </div>
            <div className="d-flex justify-content-end mt-5">
                <button className="btn btn-theme btn-small"
                        disabled={!selectedUsername}
                        onClick={() => updateInquiry(selectedUsername?.value)}>
                   <Translation text={'inquiries.inquiryList.editModal.editEmail.changeEmail'} />
                </button>
            </div>

            <Loader type={LoaderType.Local} showLoader={isLoading}/>
        </div>
    );
};
export default InquiryEmail;
