import React, {useMemo, useState, useEffect} from 'react';
import {
    CustomCard,
    CustomCardType,
    Translation,
    Form,
    FormControlChangeType,
    IFormConfig,
    TripReason,
    updateInquiryAPI,
    authTokenSelector,
    addAlert,
    handleApiError,
    IInquirySubjectOutput,
    IUpdateInquiryInput,
    AlertObject
} from 'educat-common-web';
import moment from "moment";
import {editInquiryFormConfig} from "./editInquiryFormConfig";
import {useDispatch, useSelector} from 'react-redux';
import {BehaviorSubject, of, Subscription} from "rxjs";
import {catchError, filter, map, tap} from 'rxjs/operators';
import InquiryEmail from "./InquiryEmail";

export interface IEditInsuranceFormValue {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    birthDate: string | null;
    status: typeof TripReason | null;
    school: string | null;
}

interface IInquiryEditCardProps {
    inquiry: any | null;
    closeModal: () => void;
    changeModalType: () => void;
    getInquiriesList?: () => void;
}

const InquiryEditCard: React.FC<IInquiryEditCardProps> = (props: IInquiryEditCardProps) => {
        const dispatch = useDispatch(),
            authToken = useSelector(authTokenSelector),
            onValueStateChange$ = useMemo(() => new BehaviorSubject<any>(null), []),
            [isProcessing, setIsProcessing] = useState<boolean>(false),
            [value, setValue] = useState<IEditInsuranceFormValue | null>(props.inquiry ? {
                firstName: props.inquiry?.inquirySubjects[0]?.firstName || null,
                lastName: props.inquiry?.inquirySubjects[0]?.lastName || null,
                email: props.inquiry?.inquirySubjects[0]?.email || null,
                birthDate: moment(props.inquiry?.inquirySubjects[0]?.birthDate).format('YYYY-MM-DD') || null,
                status: props.inquiry?.tripReason || null,
                school: props.inquiry?.schoolName || null,
            } : null),
            [formConfig, setFormConfig] = useState<typeof IFormConfig | null>(null);

        const setForm = (): void => {
            const formConfig = editInquiryFormConfig(value);
            setFormConfig(formConfig);
        };

    const subscriptions: Subscription[] = [];

    useEffect(() => {
        setForm();

        return() => {
            if (subscriptions) {
                subscriptions.forEach(subscription => {
                    if (subscription.unsubscribe) {
                        subscription.unsubscribe();
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        const subscription = onValueStateChange$.pipe(
            filter((data: any) => data && data.changeType === FormControlChangeType.User),
            tap((data: any) => setValue(data.value)),
        ).subscribe();

        return () => {
            subscription.unsubscribe();
        }
    }, [onValueStateChange$]);


    const onValueStateChange = (controlName: string, value: any, changeType: any) => {
        onValueStateChange$.next({controlName: controlName, value: value, changeType: changeType});
    };
    const updateData = (emailValue?: string) => {
        if ((emailValue && !props.inquiry.id) || (!value ||
            !value.firstName ||
            !value.lastName ||
            !value.birthDate ||
            !value.school ||
            !value.status ||
            !props.inquiry.id)) {
            return;
        }
        setIsProcessing(true);
        const inquirySubject: typeof IInquirySubjectOutput = props.inquiry.inquirySubjects[0];
        const insurancePayload: typeof IUpdateInquiryInput = {
            inquirySubjects: [
                {
                    firstName: emailValue ? inquirySubject.firstName : value.firstName,
                    lastName: emailValue ? inquirySubject.lastName : value.lastName,
                    birthDate: emailValue ? inquirySubject.birthDate : value.birthDate,
                    address: inquirySubject.address,
                    main: true,
                    email: emailValue ? emailValue : inquirySubject.email,
                    phone: inquirySubject.phone
                }
            ],
            applicantId: props.inquiry.applicant?.id || null,
            mentorId: props.inquiry?.mentor?.id || null,
            inquiryVariantId: props.inquiry.inquiryVariant.id,
            settled: props.inquiry.settled,
            destination: props.inquiry.destination,
            source: props.inquiry?.source || null,
            guestEmail: emailValue ? emailValue : inquirySubject.email,
            visitDate: props.inquiry.visitDate,
            alcoholClause: props.inquiry.alcoholClause,
            physicalWork: props.inquiry.physicalWork,
            schoolName: emailValue ? props.inquiry.school : value.school,
            tripReason: emailValue ? props.inquiry.tripReason : value.status,
            from: props.inquiry.from,
            to: props.inquiry.to,
            timezone: props.inquiry.timezone
        }

        return subscriptions.push(
            updateInquiryAPI(authToken, props.inquiry.id, insurancePayload).pipe(
                map(() => {
                    dispatch(addAlert({message: 'inquiries.inquiryList.editModal.insuranceUpdated'}));
                    setIsProcessing(false);
                    props.closeModal();
                }),
                tap(() => {
                    if (props.getInquiriesList) {
                        props.getInquiriesList();
                    }
                }),
                catchError((error: any) => {
                    const errorObj = handleApiError(error) as typeof AlertObject;
                    setIsProcessing(false);
                    return of(dispatch(addAlert(errorObj)))
                })
            ).subscribe()
        )
    }

    return (
        <CustomCard type={CustomCardType.MODAL_CARD} showLocalLoader={isProcessing}>
            <CustomCard.Header>
                <div className="d-flex justify-content-between align-items-center">
                    <p>
                        <Translation text={'inquiries.inquiryList.editModal.title'}/>
                    </p>
                    <div className="d-flex">
                        <button className="btn btn-action"
                                onClick={props.changeModalType}>
                            <Translation text={'inquiries.inquiryList.editModal.btnView'}/>
                        </button>
                        <button onClick={props.closeModal}>
                            <span className="feather icon-x"/>
                        </button>
                    </div>
                </div>
            </CustomCard.Header>
            <CustomCard.Body>
                {formConfig && <Form config={formConfig}
                                     onValueStateChange={onValueStateChange}
                                     value={value}
                                     submitForm={() => updateData()}
                                     controlName={'insuranceDataForm'}/>}
            </CustomCard.Body>
            <hr className="my-3"/>
            <InquiryEmail inquiryEmail={props.inquiry.guestEmail} updateInquiry={updateData}/>
        </CustomCard>
    );
};
export default InquiryEditCard;
